<template>
  <v-container>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center" class="text-center" no-gutters>
      <v-card width="90%" class="pa-10">
        <v-row align="center" justify="center" class="text-center" no-gutters>
          <v-img
            src="@/assets/logo_survey.png"
            max-width="180px"
            max-height="180px"
          />
        </v-row>
        <v-form
          ref="createForm"
          v-model="checkValidate"
          :lazy-validation="lazy"
        >
          <p style="font-size: 24px" class="headtitle">
            แบบประเมินผลการดำเนินงานโครงการดำเนินงานชุมชนยั่งยืนฯของ สภ./สน.
          </p>
          <p style="font-size: 24px" class="headtitle">(สำหรับ ภ.จว./บก.น.)</p>
          <!-- <p style="font-size: 24px" class="headtitle">
            เพื่อแก้ไขปัญหายาเสพติด แบบครบวงจร ตามยุทธศาสตร์ชาติ
          </p> -->
          <!-- <p style="font-size: 24px" class="headtitle">
            ตำรวจภูธรภาค {{ checklogin.station }}
          </p> -->
          <br />

          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="สถานี"
                outlined
                v-model="data.station"
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-menu
                ref="menufrom"
                v-model="menufrom"
                :close-on-content-click="false"
                :return-value.sync="data.datefrom"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data.datefrom"
                    label="วันที่บันทึกข้อมูลล่าสุด"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :disabled="true"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="data.datefrom" no-title scrollable>
                  <v-btn text color="primary" @click="menufrom = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menufrom.save(data.datefrom)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="ผู้ที่บันทึกข้อมูลล่าสุด"
                outlined
                v-model="data.staffName"
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="text-left pb-8">
            <v-col cols="3">
              <h3 class="headtitle">1) หัวหน้าสถานี</h3>
            </v-col>
            <v-col cols="9">
              <v-btn @click="show1()" dense v-if="!showDiv1">
                แสดงข้อมูล<v-icon right dark> mdi-chevron-down </v-icon>
              </v-btn>
              <v-btn @click="show1()" dense v-if="showDiv1">
                ซ่อนข้อมูล<v-icon right dark> mdi-chevron-up </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div v-show="showDiv1 == true">
            <v-row>
              <h3>
                1.1) เพื่อประเมิน หน.สภ/หน.สน. มีความเข้าใจและ ตอบสนองต่อ
                โครงการอย่างไรบ้าง
              </h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">1. มีการสอบถามประชาชน</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามประชาชนในชุมชน
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_1_1_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_1_1_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">2. มีการสอบถามชุดปฏิบัติการ</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจแฟ้ม
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_1_1_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_1_1_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">3. มีการนำเสนอของ หัวหน้าสถานี</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: จากการนำเสนอ
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_1_1_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_1_1_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4. มีการตรวจคำสั่ง,การประชุมสั่งการ</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: เอกสารและภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_1_1_4"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_1_1_4"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">5. มีการประสานงานกับภาคีเครือข่าย</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: เอกสารและภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_1_1_5"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_1_1_5"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">6. มีการบริหารและเบิกจ่ายงบประมาณ</b>
                <br />
                <p style="font-size: 16px; color: blue">วิธีตรวจสอบ: เอกสาร</p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_1_1_6"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_1_1_6"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >7. มีความเข้าใจและมีการใช้งานระบบ Web และ App ของโครงการ</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจสอบกับ หน.สภ./สน.
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_1_1_7"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_1_1_7"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <!-- <v-row>
            <h3 style="font-weight: 800">
              1. ผลการปฏิบัติงานของ หัวหน้าสถานี ตามแบบตรวจแนะนำ (1-7)
            </h3>
          </v-row> -->
            <!-- <v-row class="text-left" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              คะแนน
              <v-radio-group :rules="rules.required" row v-model="data.q_1_review">
                <v-radio value="1" label="น้อยที่สุด"></v-radio>
                <v-radio value="2" label="น้อย"></v-radio>
                <v-radio value="3" label="ปานกลาง"></v-radio>
                <v-radio value="4" label="มาก"></v-radio>
                <v-radio value="5" label="มากที่สุด"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.1 สรุปผลการปฏิบัติงาน</span>
              <v-textarea v-model="data.q_1_1" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.2 จุดเด่นในการปฏิบัติงาน</span>
              <v-textarea v-model="data.q_1_2" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.3 แนวทางการเสริมจุดเด่น</span>
              <v-textarea v-model="data.q_1_3" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.4 จุดที่ควรพัฒนา</span>
              <v-textarea v-model="data.q_1_4" rows="3" outlined></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.5 แนวทางการพัฒนา</span>
              <v-textarea v-model="data.q_1_5" rows="3" outlined></v-textarea>
            </v-col>
            <v-row>
              <v-col cols="12">
                <span><b>อัพโหลดรายงานผลการดำเนินงานโครงการดำเนินงานชุมชนยั่งยืน</b></span>
              </v-col>
              <v-col cols="12"
                ><v-file-input
                  chips
                  show-size
                  truncate-length="15"
                  solo
                  accept="application/pdf"
                  label="อัพโหลดไฟล์ .pdf"
                ></v-file-input
              ></v-col>
            </v-row>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row> -->
            <v-divider></v-divider>
            <br />
          </div>
          <v-row class="text-left pb-8">
            <v-col cols="3">
              <h3 class="headtitle">2) ขั้นเตรียมการ</h3>
            </v-col>
            <v-col cols="9">
              <v-btn @click="show2()" dense v-if="!showDiv2">
                แสดงข้อมูล<v-icon right dark> mdi-chevron-down </v-icon>
              </v-btn>
              <v-btn @click="show2()" dense v-if="showDiv2">
                ซ่อนข้อมูล<v-icon right dark> mdi-chevron-up </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div v-show="showDiv2 == true">
            <v-row>
              <h3>2.1) ระดับอำเภอ/ เขตศูนย์ปฏิบัติการ ยาเสพติดอำเภอ/ เขต</h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">1. มีการทำงานอย่างบูรณาการ</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สุ่มสอบถามผู้เกี่ยวข้อง
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_1_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_1_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >2. มีการประชุมร่วม นายอำเภอ/ผอ.เขต ในนาม หน.ศูนย์ฯ
                  และสั่งการด้วยตัวเองโดยมี ตร.,สธ. และภาคีเครือข่ายร่วมด้วย</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูจากแฟ้มคำสั่งและภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_1_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_1_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >3. มีคำสั่ง ศูนย์ยาเสพติดในระดับ อำเภอ/เขต และจังหวัด/กทม.
                  ถูกต้อง</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: แฟ้มคำสั่ง
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_1_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_1_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4. มีการประสานงานระดับ จังหวัด,อำเภอ,เขต</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: แฟ้มคำสั่ง และหรือภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_1_4"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_1_4"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <h3>2.2) ชุดปฏิบัติการ</h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >1. มีการแต่งตั้งชุดปฏิบัติการมีความเหมาะสม</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจสอบตำรวจชุดปฏิบัติการ
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_2_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_2_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >2. มีความเข้าใจในกระบวนการทำงานของโครงการชุมชนยั่งยืน</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจสอบตำรวจชุดปฏิบัติการ
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_2_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_2_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >3. มีความตื่นตัว,การเรียนรู้ และการเข้าหาประชาชน</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามประชาชน,ผู้นำชุมชน
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_2_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_2_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4. มีการประชาสัมพันธ์ การใช้ web และ App ของโครงการ</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามประชาชน,ผู้นำชุมชน และหรือภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_2_4"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_2_4"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >5. ประชาชนมีความเข้าใจและการใช้ web และ app โดยประชาชน</b
                >
              </v-col>
              <br />
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">5.1 มีการร้องเรียนแจ้งเบาะแส</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามประชาชน, ผู้นำชุมชน
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_2_5_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_2_5_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">5.2 มีการกรอกแบบประเมิน</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามประชาชน, ผู้นำชุมชน
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_2_5_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_2_5_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <h3>2.3)สถานที่ตั้งศูนย์ฯ</h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >1. มีความเหมาะสม พิจารณาจากเป็นจุดศูนย์รวม
                  มีการเดินทางเข้าออกสะดวก มีสถานที่จัดกิจกรรมและประชุมได้
                  ป้ายศูนย์และป้ายบอกทาง</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูสถานที่จริง และหรือภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_3_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_3_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <h3>2.4)อุปกรณ์</h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">1. มียานพาหนะ ประจำและสถาพสมบูรณ์</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูสถานที่จริง และหรือภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_4_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_4_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">2. มีบอร์ดป้ายคณะกรรมการคุ้ม</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูสถานที่จริง และหรือภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_4_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_4_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">3. มีบอร์ดแผนที่เดินดิน</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูสถานที่จริง และหรือภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_4_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_4_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">4. มีบอร์ดแผนผังสรุปผลการ x-ray</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูสถานที่จริง และหรือภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_4_4"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_4_4"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >5. มีบอร์ดตารางเวลาการทำงาน (Timeline)</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูสถานที่จริง และหรือภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_4_5"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_4_5"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">6. มีบอร์ดความร่วมมือ (MOU)</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูสถานที่จริง และหรือภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_4_6"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_4_6"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <!-- <v-row> -->
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >7. มีแฟ้มเอกสารที่จัดเก็บอย่างเป็นระบบ</b
                >
                <br />
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >7.1 เอกสารคำสั่งแต่งตั้งคณะกรรมการคุ้ม</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจสอบเอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_4_7_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_4_7_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >7.2 คณะกรรมการกลั่นกรองบุคคล/ครัวเรือนสีขาว</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจสอบเอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_4_7_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_4_7_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">7.3 สำมะโนประชากร</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจสอบเอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_4_7_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_4_7_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >7.4 สมุดบันทึกการตรวจเยี่ยมของ ผู้บังคับบัญชา</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจสอบเอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_4_7_4"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_4_7_4"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">7.5 สมุดบันทึกประจำวัน</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจสอบเอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_4_7_5"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_4_7_5"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <h3>2.5) การสืบสภาพชุมชน</h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >1. มีการออกพบปะผู้นำชุมชนอย่างน้อย 2 ครั้ง</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจสอบแฟ้ม,ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_5_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_5_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">2. มีกิจกรรมกับประชาคมกลุ่มย่อย</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามผู้นำชุมชนแลคุ้ม, ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_5_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_5_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >3. มีการใช้ข้อมูล เหตุผลในการเลือกชุมชน</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจสอบกับตำรวจชุดปฏิบัติการ
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_5_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_5_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4. มีการเดินเผชิญสืบ/จัดทำแผนที่เดินดิน</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจสอบกับตำรวจชุดปฏิบัติการ
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_5_4"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_5_4"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >5. กิจกรรมมีส่วนร่วมกับประชาชน เช่น
                  กิจกรรมจิตอาสาพัฒนา/ร่วมประเพณี
                  วัฒนธรรมชุมชนท้องถิ่น/บริการประชาชน</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ประชาชน,ผู้นำชุมชน,ผู้นำศาสนา,ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_5_5"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_5_5"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >6. มีการทำแบบสอบถามและการรวบรวมข้อมูลในชุมชน</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจสอบเอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_5_6"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_5_6"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row><h3>2.6) กระบวนการคุ้ม</h3></v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >1. มีการจัดทำป้ายคุ้มและติตตั้งจุดที่เหมาะสม</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สุ่มตรวจ
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_6_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_6_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">2. มีคำสั่งแต่งตั้งคณะกรรมการคุ้ม</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจแฟ้ม
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_6_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_6_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">3. มีป้ายบอร์ดคณะกรรมการคุ้ม</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจสถานที่จริง และหรือภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_6_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_6_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4.
                  มีการสร้างความเข้าใจในการทำงานและบทบาทหน้าที่ของคณะกรรมการคุ้ม</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สุ่มตรวจคุ้ม,สอบถามคณะกรรมการคุ้ม
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_6_4"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_6_4"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >5. การแบ่งคุ้มมีความเหมาะสม 1คุ้ม ควรมีครัวเรือนไม่เกิน 30
                  หลังคา</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจแฟ้ม,สอบถามคณะกรรมการคุ้ม
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_6_5"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_6_5"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >6. มีแฟ้มบัญชีสำมะโนประชากรประจำคุ้ม</b
                >
                <br />
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">6.1 ข้อมูลประชากรตาม ทร.14</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจแฟ้ม
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_6_6_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_6_6_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">6.2 ข้อมูลครัวเรือนประชากรจริง</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจแฟ้ม
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_2_6_6_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_2_6_6_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <!-- <v-row>
              <h3 style="font-weight: 800">
                2. ผลการปฏิบัติงาน ขั้นเตรียมการ ตามแบบตรวจแนะนำ (2.1 – 2.6)
              </h3>
            </v-row> -->
              <!-- <v-row class="text-left" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
              คะแนน
              <v-radio-group :rules="rules.required" row v-model="data.q_2_review">
                <v-radio value="1" label="น้อยที่สุด"></v-radio>
                <v-radio value="2" label="น้อย"></v-radio>
                <v-radio value="3" label="ปานกลาง"></v-radio>
                <v-radio value="4" label="มาก"></v-radio>
                <v-radio value="5" label="มากที่สุด"></v-radio>
              </v-radio-group>
            </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>2.1 สรุปผลการปฏิบัติงาน</span>
                <v-textarea
                  v-model="data.q_2_1"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>2.2 จุดเด่นในการปฏิบัติงาน</span>
                <v-textarea
                  v-model="data.q_2_2"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>2.3 แนวทางการเสริมจุดเด่น</span>
                <v-textarea
                  v-model="data.q_2_3"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>2.4 จุดที่ควรพัฒนา</span>
                <v-textarea
                  v-model="data.q_2_4"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>2.5 แนวทางการพัฒนา</span>
                <v-textarea
                  v-model="data.q_2_5"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-row>
                <v-col cols="12">
                  <span><b>อัพโหลดรายงานผลการดำเนินงานโครงการดำเนินงานชุมชนยั่งยืน</b></span>
                </v-col>
                <v-col cols="12"
                  ><v-file-input
                    chips
                    show-size
                    truncate-length="15"
                    solo
                    accept="application/pdf"
                    label="อัพโหลดไฟล์ .pdf"
                  ></v-file-input
                ></v-col>
              </v-row>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row> -->
              <v-divider></v-divider>
            </v-row>
          </div>
          <v-row class="text-left pb-8">
            <v-col cols="3">
              <h3 class="headtitle">3)ขั้นตอนการปฏิบัติ</h3>
            </v-col>
            <v-col cols="9">
              <v-btn @click="show3()" dense v-if="!showDiv3">
                แสดงข้อมูล<v-icon right dark> mdi-chevron-down </v-icon>
              </v-btn>
              <v-btn @click="show3()" dense v-if="showDiv3">
                ซ่อนข้อมูล<v-icon right dark> mdi-chevron-up </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div v-show="showDiv3">
            <v-row>
              <h3>3.1) กระบวนการค้นหาผู้ค้า ผู้เสพแบบครบวงจร</h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">1. มีการบันทึก MOU ครัวเรือน</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_1_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_1_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >2. มีการตรวจปัสสาวะ 100%
                  โดยมีประชาคมยกเว้นกลุ่มผู้ไม่ต้องตรวจ (เกณฑ์อายุ 12 – 65
                  ปี)</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_1_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_1_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >3. มีกระบวนการผ้าป่าเบาะแส (แจ้งข้อมูลผ่านซองจดหมาย)</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_1_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_1_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4. มีสืบค้นจากฐานข้อมูลระบบ Crimes (3 ปีย้อนหลัง)</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูแฟ้มเอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_1_4"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_1_4"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >5. มีประชาสัมพันธ์ให้ออกมารายงานตัว</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามผู้นำชุมชน และหรือภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_1_5"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_1_5"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >6. มีข้อมูลจากงานสืบสวน/ผู้นำชุมชน/จากฝ่ายปกครอง</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามผู้นำชุมชน
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_1_6"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_1_6"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">7. มีข้อมูลบุคคลพ้นโทษ</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_1_7"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_1_7"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >8. มีกระบวนการกลั่นกรองพลเมืองสีขาว</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_1_8"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_1_8"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">9. มีการกำหนดกติการการตรวจปัสสาวะ</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามสาธารณสุขพื้นที่ และหรือ ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_1_9"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_1_9"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >10. มีแนวทางการตรวจปัสสาวะให้เร็วและครบถ้วน</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามประชาชน, ผู้นำชุมชน
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_1_10"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_1_10"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <h3>3.2) กระบวนการกลั่นกรองบุคคลและครัวเรือนสีขาว</h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >1. มีคำสั่งแต่งตั้งคณะกรรมการกลั่นกรอง</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูเอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_2_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_2_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">2. มีการประชุมพิจารณากลั่นกรอง</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร สอบถามกับหัวหน้าคุ้ม
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_2_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_2_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">3. จัดให้มีการมอบบัตรพลเมืองสีขาว</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_2_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_2_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4. จัดให้มีการมอบป้ายครัวเรือนสีขาว</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_2_4"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_2_4"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">5. จัดให้มีการมอบป้ายคุ้มสีขาว</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_2_5"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_2_5"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <h3>3.3) กระบวนการบำบัด</h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >1. มีฝ่ายสาธารณสุขคัดกรองผู้เสพนำเข้าสู่กระบวนการบำบัด บันทึก
                  บสต. โดยสาธารณสุข</b
                >
                <!-- <br />
              <p style="font-size: 16px; color: blue">
                วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
              </p> -->
              </v-col>
              <!-- <v-col cols="12" md="6" sm="6" xs="12">
              คะแนน
              <v-radio-group :rules="rules.required" row v-model="data.q_3_3_1_1">
                <v-radio value="1" label="1"></v-radio>
                <v-radio value="2" label="2"></v-radio>
                <v-radio value="3" label="3"></v-radio>
                <v-radio value="4" label="4"></v-radio>
                <v-radio value="5" label="5"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                v-model="data.r_3_3_1_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col> -->
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >1.1 มีการบันทึกข้อมูลผู้เสพใน ระบบ NISPA โดยฝ่ายปกครอง</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามฝ่ายปกครอง, ตรวจเอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_1_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_1_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >1.2 มีการบันทึกข้อมูลผู้เสพใน ระบบ บสต.
                  โดยสาธารณสุขพ้ืนที่</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามฝ่ายปกครอง, ตรวจเอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_1_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_1_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >2. มีการนำบัญชีรายชื่อผู้สมัครใจบำบัดไปลง
                  ประจำวันเป็นหลักฐานที่ สน. สภ. ทุกราย (ตามประกาศ คสช.
                  108/57)</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ตรวจเอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >3. มีคำสั่ง ศป. ปส. อำเภอ/เขต ประกาศให้ ศปก
                  ชุมชนยั่งยืนเป็นศูนย์บำบัดฟื้นฟูผู้ป่วยยาเสพติดประจำชุมชน</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4. มีการจัดทำหลักสูตรร่วมกับฝ่ายสาธารณสุข</b
                >
                <br />
                <!-- <p style="font-size: 16px; color: blue">
                วิธีตรวจสอบ: ภาพถ่าย/เอกสาร สอบถามผู้นำชุมชน ภาคีเครือข่าย
              </p> -->
              </v-col>
              <!-- <v-col cols="12" md="6" sm="6" xs="12">
              คะแนน
              <v-radio-group :rules="rules.required" row v-model="data.q_3_3_4">
                <v-radio value="1" label="1"></v-radio>
                <v-radio value="2" label="2"></v-radio>
                <v-radio value="3" label="3"></v-radio>
                <v-radio value="4" label="4"></v-radio>
                <v-radio value="5" label="5"></v-radio>
              </v-radio-group>
            </v-col> -->
              <!-- <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                v-model="data.r_3_3_4"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col> -->
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">4.1 มีตารางบำบัดทางการแพทย์</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_4_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_4_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4.2 มีตารางบำบัดด้วยกระบวนการชุมชนบำบัด</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_4_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_4_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4.3
                  มีตารางบำบัดด้วยการฟื้นฟูร่างกาย-จิตใจและกายจิตสังคมบำบัด</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_4_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_4_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >5. มีการจัดทำสมุดประจำตัวผู้ป่วย (ผู้เข้าบำบัด)
                  เพื่อบันทึกผลกาตรวจปัสสาวะ/กิจกรรม
                  มีตารางการนัดหมายหรือบัตรนัด</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูเอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_5"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_5"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >6. มีบุคคลหรือกลุ่มบุคคลร่วม กิจกรรมชุมชนบาบัดอย่างกลมกลืน
                  และต่อเนื่อง</b
                >
                <!-- <br />
              <p style="font-size: 16px; color: blue">
                วิธีตรวจสอบ: ภาพถ่าย/เอกสาร สัมภาษณ์ผู้นำชุมชน
              </p> -->
              </v-col>
              <!-- <v-col cols="12" md="6" sm="6" xs="12">
              คะแนน
              <v-radio-group :rules="rules.required" row v-model="data.q_3_3_6">
                <v-radio value="1" label="1"></v-radio>
                <v-radio value="2" label="2"></v-radio>
                <v-radio value="3" label="3"></v-radio>
                <v-radio value="4" label="4"></v-radio>
                <v-radio value="5" label="5"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                v-model="data.r_3_3_6"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col> -->
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">6.1 ประชาชน</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่ายเอกสาร,สอบถาม ผู้เกี่ยวข้อง
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_6_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_6_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">6.2 เยาวชน</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่ายเอกสาร,สอบถาม ผู้เกี่ยวข้อง
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_6_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_6_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">6.3 ผู้นำชุมชน</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่ายเอกสาร,สอบถาม ผู้เกี่ยวข้อง
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_6_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_6_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">6.4 ผู้นำศาสนา</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่ายเอกสาร,สอบถาม ผู้เกี่ยวข้อง
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_6_4"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_6_4"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">6.5 ภาคเอกชน</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่ายเอกสาร,สอบถาม ผู้เกี่ยวข้อง
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_6_5"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_6_5"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">6.6 ภาคประชาสังคม</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่ายเอกสาร,สอบถาม ผู้เกี่ยวข้อง
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_6_6"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_6_6"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">6.7 อื่น ๆ</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่ายเอกสาร,สอบถาม ผู้เกี่ยวข้อง
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_3_6_7"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_3_6_7"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row><h3>3.4 กิจกรรมส่งเสริมชุมชนเข้มแข็ง</h3> </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >1. มีการวางแผนการประชุมและมอบหน้าที่ การตรวจ, การจับ,
                  การตั้งด่าน, การระงับเหตุ, การบำบัดและการพัฒนา</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร สอบถามสาธารณสุข
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_4_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_4_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >2. กิจกรรมเสริมสร้างภูมิคุ้มกันให้กับชุมชน เช่น
                  การให้ความรู้เยาวชน, นักเรียน, โรงงาน โครงการต่าง ๆ,
                  อบรมการใช้ Web และ App</b
                >
                <!-- <br />
              <p style="font-size: 16px; color: blue">
                วิธีตรวจสอบ: ภาพถ่าย/เอกสาร สอบถามสาธารณสุข/ผู้นำ ชุมชน
              </p> -->
              </v-col>
              <!-- <v-col cols="12" md="6" sm="6" xs="12">
              คะแนน
              <v-radio-group :rules="rules.required" row v-model="data.q_3_4_2">
                <v-radio value="1" label="1"></v-radio>
                <v-radio value="2" label="2"></v-radio>
                <v-radio value="3" label="3"></v-radio>
                <v-radio value="4" label="4"></v-radio>
                <v-radio value="5" label="5"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                v-model="data.r_3_4_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col> -->
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">2.1 กิจกรรม/โครงการที่ 1</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_4_2_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_4_2_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">2.2 กิจกรรม/โครงการที่ 2</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_4_2_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_4_2_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">2.3 กิจกรรม/โครงการที่ 3</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_4_2_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_4_2_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">2.4 กิจกรรม/โครงการที่ 4</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_4_2_4"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_4_2_4"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">2.5 กิจกรรม/โครงการที่ 5</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_4_2_5"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_4_2_5"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">2.6 กิจกรรม/โครงการอื่น ๆ</b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_4_2_6"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_4_2_6"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >3. มีการพัฒนาและการแก้ไขปัญหาในชุมชน</b
                >
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามผู้เสพหรือผู้นำ ชุมชน, ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_3_4_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_3_4_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <!-- <v-row>
              <h3 style="font-weight: 800">
                3. ผลการปฏิบัติงาน ขั้นปฏิบัติการ ตามแบบตรวจแนะนำ (3.1 – 3.4 )
              </h3>
            </v-row> -->
              <!-- <v-row class="text-left" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
              คะแนน
              <v-radio-group :rules="rules.required" row v-model="data.q_3_review">
                <v-radio value="1" label="น้อยที่สุด"></v-radio>
                <v-radio value="2" label="น้อย"></v-radio>
                <v-radio value="3" label="ปานกลาง"></v-radio>
                <v-radio value="4" label="มาก"></v-radio>
                <v-radio value="5" label="มากที่สุด"></v-radio>
              </v-radio-group>
            </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>3.1 สรุปผลการปฏิบัติงาน</span>
                <v-textarea
                  v-model="data.q_3_1"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>3.2 จุดเด่นในการปฏิบัติงาน</span>
                <v-textarea
                  v-model="data.q_3_2"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>3.3 แนวทางการเสริมจุดเด่น</span>
                <v-textarea
                  v-model="data.q_3_3"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>3.4 จุดที่ควรพัฒนา</span>
                <v-textarea
                  v-model="data.q_3_4"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>3.5 แนวทางการพัฒนา</span>
                <v-textarea
                  v-model="data.q_3_5"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-row>
                <v-col cols="12">
                  <span><b>อัพโหลดรายงานผลการดำเนินงานโครงการดำเนินงานชุมชนยั่งยืน</b></span>
                </v-col>
                <v-col cols="12"
                  ><v-file-input
                    chips
                    show-size
                    truncate-length="15"
                    solo
                    accept="application/pdf"
                    label="อัพโหลดไฟล์ .pdf"
                  ></v-file-input
                ></v-col>
              </v-row>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row> -->
              <v-divider></v-divider>
            </v-row>
          </div>
          <v-row class="text-left pb-8">
            <v-col cols="3">
              <h3 class="headtitle">4) ขั้นส่งต่อความยั่งยืน</h3>
            </v-col>
            <v-col cols="9">
              <v-btn @click="show4()" dense v-if="!showDiv4">
                แสดงข้อมูล<v-icon right dark> mdi-chevron-down </v-icon>
              </v-btn>
              <v-btn @click="show4()" dense v-if="showDiv4">
                ซ่อนข้อมูล<v-icon right dark> mdi-chevron-up </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div v-show="showDiv4">
            <v-row>
              <h3>4.1) กระบวนการส่งต่อความยั่งยืน</h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >1. มีป้าย ข้อมูลพื้นฐานของชุมชน -
                  สรุปผลการค้นหาผู้เข้าร่วมบำบัดตามโครงการ
                </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูสถานที่/ดูเอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >2. มีแผนการตรวจปัสสาวะ
                  โดยภาคีเครือข่ายทุกสัปดาห์และติดตามผลอย่างต่อเนื่อง
                </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ภาพถ่าย/เอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >3. มีสมุดคู่มือประจำผู้ป่วย (ส่งมอบ หัวหน้าคุ้มเก็บรักษา)
                </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามผู้นำชุมชน
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4. มีคำสั่ง ศอ.ปส. อำเภอ/เขต เพื่อติดตาม-ประเมินผล-ประสานงาน
                </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามภาคีเครือข่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_4"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_4"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >5. จัดให้มีการนำเข้าวาระการประชุมในระดับ หมู่บ้าน/ชุมชน
                  ตำบล/แขวง อำเภอ/เขต ทุกเดือน
                </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามผู้ร่วมโครงการ
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_5"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_5"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >6. จัดให้มีการนำเข้าวาระการประชุมในระดับ อำเภอ/เขต
                  จังหวัด/กรุงเทพฯ
                </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: สอบถามผู้ร่วมโครงการ
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_6"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_6"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >7. มีประชาคม ระดับคุ้ม ระดับหมู่บ้าน ชาวบ้าน เพื่อสร้างกฎ
                  กติกา ธรรมนูญ หมู่บ้าน/ชุมชน
                </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูเอกสาร
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_7"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_7"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <h3>8) มีการส่งเสริมคุณภาพชีวิตของผู้รับการบำบัด</h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">8.1 จัดหางาน </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_8_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_8_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">8.2 ฝึกอาชีพ </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_8_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_8_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">8.3 สร้างโอกาสทางการศึกษา </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_8_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_8_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">8.4 อื่นๆ </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_8_4"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_8_4"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <h3>9.) การประสาน ร่วมมือ สนับสนุน ช่วยเหลือจากภาคส่วนต่าง ๆ</h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <!-- <b style="font-size: 18px">9.1 หน่วยงานภาครัฐ </b> -->
                <!-- <br />
              <p style="font-size: 16px; color: blue">
                วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย
              </p> -->
              </v-col>
              <!-- <v-col cols="12" md="6" sm="6" xs="12">
              คะแนน
              <v-radio-group :rules="rules.required" row v-model="data.q_4_1_9_1">
                <v-radio value="1" label="1"></v-radio>
                <v-radio value="2" label="2"></v-radio>
                <v-radio value="3" label="3"></v-radio>
                <v-radio value="4" label="4"></v-radio>
                <v-radio value="5" label="5"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                v-model="data.r_4_1_9_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col> -->
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">9.1 หน่วยงานภาครัฐ </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_9_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_9_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">9.2 หน่วยงานภาคเอกชน </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_9_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_9_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">9.3 ภาคประชาสังคม </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_9_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_9_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">9.4 NGOS </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_9_4"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_9_4"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">9.5 อื่น ๆ </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_9_5"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_9_5"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <h3>10.) การประสานความช่วยเหลือหน่วยงานต่าง ๆ</h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">10. มีการจัดระบบ ช่องทางสื่อสาร </b>
                <br />
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">10.1 ระบบไลน์ เบอรโทรศัพท์ </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูโซเชียล
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_10_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_10_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">10.2 เพจเฟสบุ๊ค </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูโซเชียล
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_10_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_10_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">10.3 อื่นๆ </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูโซเชียล
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_10_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  disabled
                  v-model="data.r_4_1_10_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >11 สถานีตำรวจออกคำสั่งให้ จนท. ตำรวจ ตรวจเยี่ยม
                  อย่างน้อยเดือนละ 2 ครั้ง
                </b>
                <br />
                <p style="font-size: 16px; color: blue">วิธีตรวจสอบ: เอกสาร</p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_11"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_1_11"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <h3>4.2) การปรับสภาพระบบนิเวศเชิงลบ</h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >1. มีการปรับปรุงแสงส่องสว่าง จุดเสี่ยง
                </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูพื้นที่จริง
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_2_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_2_1"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >2. มีการปรับภูมิทัศน์ สภาพแวดล้อมชุมชน เช่น ความโปร่ง โล่ง
                  สะอาด สวยงาม อย่างต่อเนื่อง
                </b>
                <br />
                <p style="font-size: 16px; color: blue">วิธีตรวจสอบ: ภาพถ่าย</p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_2_2"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_2_2"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >3.
                  การดำเนินการกับบุคคลที่ก่อให้เกิดความไม่สงบเรียบร้อยในชุมชน
                </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: เอกสาร/ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_2_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_2_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <h3>4.3)กระบวนการรักษาความปลอดภัยและความสงบเรียบร้อยในชุมชน</h3>
            </v-row>
            <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >1.
                  มีการแสวงหาความร่วมมือจากองค์กรปกครองส่วนท้องถิ่นหรือภาคเอกชนในพื้นที่
                </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: เอกสาร แผน ภาพถ่าย
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_3_1"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  v-model="data.r_4_3_1"
                  label="หมายเหตุ ต้องมีหนังสือนำส่งโครงการในการดำเนินการ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <!-- <v-row>
              <h3 style="font-weight: 800">
                4. ผลการปฏิบัติงาน ขั้นส่งต่อความยั่งยืน ตามแบบตรวจแนะนำ (4.1 –
                4.3 )
              </h3>
            </v-row> -->
              <!-- <v-row class="text-left" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
              คะแนน
              <v-radio-group :rules="rules.required" row v-model="data.q_4_review">
                <v-radio value="1" label="น้อยที่สุด"></v-radio>
                <v-radio value="2" label="น้อย"></v-radio>
                <v-radio value="3" label="ปานกลาง"></v-radio>
                <v-radio value="4" label="มาก"></v-radio>
                <v-radio value="5" label="มากที่สุด"></v-radio>
              </v-radio-group>
            </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>4.1 สรุปผลการปฏิบัติงาน</span>
                <v-textarea
                  v-model="data.q_4_1"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>4.2 จุดเด่นในการปฏิบัติงาน</span>
                <v-textarea
                  v-model="data.q_4_2"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>4.3 แนวทางการเสริมจุดเด่น</span>
                <v-textarea
                  v-model="data.q_4_3"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>4.4 จุดที่ควรพัฒนา</span>
                <v-textarea
                  v-model="data.q_4_4"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>4.5 แนวทางการพัฒนา</span>
                <v-textarea
                  v-model="data.q_4_5"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-row>
              <v-col cols="12">
                <span><b>อัพโหลดรายงานผลการดำเนินงานโครงการดำเนินงานชุมชนยั่งยืน</b></span>
              </v-col>
              <v-col cols="12"
                ><v-file-input
                  chips
                  show-size
                  truncate-length="15"
                  solo
                  accept="application/pdf"
                  label="อัพโหลดไฟล์ .pdf"
                ></v-file-input
              ></v-col>
            </v-row>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row> -->
              <v-divider></v-divider>
            </v-row>
          </div>
          <!-- <v-row>
            <h3 class="headtitle">อัพโหลดรูปภาพกิจกรรมโครงการ (4รูป)</h3>
          </v-row> -->
          <!-- <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <span>อัพโหลดรูปภาพที่ 1</span>
              <v-img
                :src="data.imgUrl1"
                height="250"
                contain
                v-if="data.imgUrl1 && data.imgUrl1.length < 200"
              ></v-img>
              <v-img
                :src="showImage1"2
                height="250"
                contain
                v-if="!data.imgUrl1"
              ></v-img>
              <v-img
                :src="showImage1"
                height="250"
                contain
                v-if="data.imgUrl1 && data.imgUrl1.length > 200"
              ></v-img>
              <v-text-field
                v-model="data.imageName1"
                @click="changePic1()"
                :label="data.imageName1"
                prepend-icon="mdi-camera"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure1"
                accept="image/*"
                @change="showPicture1($event)"
                style="display: none"
              />
            </v-col>
            </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <span>อัพโหลดรูปภาพที่ 2</span>
              <v-img
                :src="data.imgUrl2"
                height="250"
                contain
                v-if="data.imgUrl2 && data.imgUrl2.length < 200"
              ></v-img>
              <v-img
                :src="showImage2"
                height="250"
                contain
                v-if="!data.imgUrl2"
              ></v-img>
              <v-img
                :src="showImage2"
                height="250"
                contain
                v-if="data.imgUrl2 && data.imgUrl2.length > 200"
              ></v-img>
              <v-text-field
                v-model="data.imageName2"
                @click="changePic2()"
                :label="data.imageName2"
                prepend-icon="mdi-camera"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure2"
                accept="image/*"
                @change="showPicture2($event)"
                style="display: none"
              />
            </v-col>
          </v-row> -->
          <!-- <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <span>อัพโหลดรูปภาพที่ 3</span>
              <v-img
                :src="data.imgUrl3"
                height="250"
                contain
                v-if="data.imgUrl3 && data.imgUrl3.length < 200"
              ></v-img>
              <v-img
                :src="showImage3"
                height="250"
                contain
                v-if="!data.imgUrl3"
              ></v-img>
              <v-img
                :src="showImage3"
                height="250"
                contain
                v-if="data.imgUrl3 && data.imgUrl3.length > 200"
              ></v-img>
              <v-text-field
                v-model="data.imageName3"
                @click="changePic3()"
                :label="data.imageName3"
                prepend-icon="mdi-camera"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure3"
                accept="image/*"
                @change="showPicture3($event)"
                style="display: none"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <span>อัพโหลดรูปภาพที่ 4</span>
              <v-img
                :src="data.imgUrl4"
                height="250"
                contain
                v-if="data.imgUrl4 && data.imgUrl4.length < 200"
              ></v-img>
              <v-img
                :src="showImage4"
                height="250"
                contain
                v-if="!data.imgUrl4"
              ></v-img>
              <v-img
                :src="showImage4"
                height="250"
                contain
                v-if="data.imgUrl4 && data.imgUrl4.length > 200"
              ></v-img>
              <v-text-field
                v-model="data.imageName4"
                @click="changePic4()"
                :label="data.imageName4"
                prepend-icon="mdi-camera"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure4"
                accept="image/*"
                @change="showPicture4($event)"
                style="display: none"
              />
            </v-col>
          </v-row> -->

          <!-- <v-row>
            <v-col cols="12">
              <span>อัพโหลดไฟล์แนบสรุป</span>
            </v-col>
            <v-col cols="12"
              ><v-file-input
                chips
                show-size
                truncate-length="15"
                solo
                accept="application/pdf"
                label="อัพโหลดไฟล์ .pdf"
              ></v-file-input
            ></v-col>
          </v-row> -->
          <v-row v-if="summaryData">
            <h3 style="font-weight:800;padding-top:20px">สรุปค่าคะแนนประเมินผลการดำเนินการ โครงการดำเนินงานชุมชนยั่งยืนฯ ตามขั้นตอนการปฏิบัติ สำหรับ ภ.จว./บก.น.</h3>
           <v-col cols="12">
              <v-data-table
            :headers="headersTable"
              :items="summaryDataArray"
              disable-pagination
              hide-default-footer
              class="elevation-4"
            >
            <template v-slot:[`item.value`]="{ item }">
              <span><b>{{ item.value }}</b></span>
            </template>
            </v-data-table>
           </v-col>
          </v-row>
          <v-row>
            <v-col><v-btn @click="cancel()">ยกเลิก</v-btn></v-col>
            <v-col>
              <v-btn color="primary" @click="submit()">บันทึก</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      headersTable: [
        { text: "ลำดับ", value: "number", align: "center" },
        { text: "หัวข้อ", value: "title", align: "center" },
        { text: "ค่าคะแนนที่ได้", value: "value", align: "center" },
      ],
      summaryData:null,
      summaryDataArray:[],
      showDiv1: true,
      showDiv2: true,
      showDiv3: true,
      showDiv4: true,
      loading: false,
      checkValidate: true,
      lazy: false,
      rules: {
        email: [(v) => !!(v || "").match(/@/) || "Please enter a valid email"],
        // length: (len) => (v) =>
        //   (v || "").length >= len ||
        //   `Invalid character length, required ${len}`,
        maxlength13: [(v) => v.length <= 13 || "Max 13 characters"],
        maxlength10: [(v) => v.length <= 10 || "Max 10 characters"],
        maxAge: [
          (v) => v.length <= 2 || "ระบุอายุระหว่าง 12-60ปี เท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูล",
        ],
        password: [
          (v) =>
            !!(v || "").match(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
            ) ||
            "Password must contain an upper case letter, a numeric character, and a special character",
        ],
        required: [(v) => !!v || "กรุณาให้คะแนน"],
      },
      checklogin: [],
      // data: {
      //   station: "",
      //   datefrom: "",
      //   userId: "",
      // },
      data: {
        station: "",
        datefrom: "",
        userId: "",
        staffName: "",
        q_1_review: "",
        q_1_1: "",
        q_1_2: "",
        q_1_3: "",
        q_1_4: "",
        q_1_5: "",
        q_2_review: "",
        q_2_1: "",
        q_2_2: "",
        q_2_3: "",
        q_2_4: "",
        q_2_5: "",
        q_3_review: "",
        q_3_1: "",
        q_3_2: "",
        q_3_3: "",
        q_3_4: "",
        q_3_5: "",
        q_4_review: "",
        q_4_1: "",
        q_4_2: "",
        q_4_3: "",
        q_4_4: "",
        q_4_5: "",
        // },
        // data: {
        // station: "",
        // datefrom: "",
        // userId: "",
        // staffName: "",
        q_1_1_1: "",
        q_1_1_2: "",
        q_1_1_3: "",
        q_1_1_4: "",
        q_1_1_5: "",
        q_1_1_6: "",
        q_1_1_7: "",
        q_2_1_1: "",
        q_2_1_2: "",
        q_2_1_3: "",
        q_2_1_4: "",
        q_2_2_1: "",
        q_2_2_2: "",
        q_2_2_3: "",
        q_2_2_4: "",
        q_2_2_5_1: "",
        q_2_2_5_2: "",
        q_2_3_1: "",
        q_2_4_1: "",
        q_2_4_2: "",
        q_2_4_3: "",
        q_2_4_4: "",
        q_2_4_5: "",
        q_2_4_6: "",
        q_2_4_7_1: "",
        q_2_4_7_2: "",
        q_2_4_7_3: "",
        q_2_4_7_4: "",
        q_2_4_7_5: "",
        q_2_5_1: "",
        q_2_5_2: "",
        q_2_5_3: "",
        q_2_5_4: "",
        q_2_5_5: "",
        q_2_5_6: "",
        q_2_6_1: "",
        q_2_6_2: "",
        q_2_6_3: "",
        q_2_6_4: "",
        q_2_6_5: "",
        q_2_6_6_1: "",
        q_2_6_6_2: "",
        q_3_1_1: "",
        q_3_1_2: "",
        q_3_1_3: "",
        q_3_1_4: "",
        q_3_1_5: "",
        q_3_1_6: "",
        q_3_1_7: "",
        q_3_1_8: "",
        q_3_1_9: "",
        q_3_1_10: "",
        q_3_2_1: "",
        q_3_2_2: "",
        q_3_2_3: "",
        q_3_2_4: "",
        q_3_2_5: "",
        q_3_3_1_1: "",
        q_3_3_1_2: "",
        q_3_3_2: "",
        q_3_3_3: "",
        q_3_3_4: "",
        q_3_3_4_1: "",
        q_3_3_4_2: "",
        q_3_3_4_3: "",
        q_3_3_5: "",
        q_3_3_6_1: "",
        q_3_3_6_2: "",
        q_3_3_6_3: "",
        q_3_3_6_4: "",
        q_3_3_6_5: "",
        q_3_3_6_6: "",
        q_3_3_6_7: "",
        q_3_4_1: "",
        q_3_4_2_1: "",
        q_3_4_2_2: "",
        q_3_4_2_3: "",
        q_3_4_2_4: "",
        q_3_4_2_5: "",
        q_3_4_2_6: "",
        q_3_4_3: "",
        q_4_1_1: "",
        q_4_1_2: "",
        q_4_1_3: "",
        q_4_1_4: "",
        q_4_1_5: "",
        q_4_1_6: "",
        q_4_1_7: "",
        q_4_1_8_1: "",
        q_4_1_8_2: "",
        q_4_1_8_3: "",
        q_4_1_8_4: "",
        q_4_1_9_1: "",
        q_4_1_9_2: "",
        q_4_1_9_3: "",
        q_4_1_9_4: "",
        q_4_1_9_5: "",
        q_4_1_10_1: "",
        q_4_1_10_2: "",
        q_4_1_10_3:"",
        q_4_1_11: "",
        q_4_2_1: "",
        q_4_2_2: "",
        q_4_2_3: "",
        q_4_3_1: "",

        r_1_1_1: "",
        r_1_1_2: "",
        r_1_1_3: "",
        r_1_1_4: "",
        r_1_1_5: "",
        r_1_1_6: "",
        r_1_1_7: "",
        r_2_1_1: "",
        r_2_1_2: "",
        r_2_1_3: "",
        r_2_1_4: "",
        r_2_2_1: "",
        r_2_2_2: "",
        r_2_2_3: "",
        r_2_2_4: "",
        r_2_2_5_1: "",
        r_2_2_5_2: "",
        r_2_3_1: "",
        r_2_4_1: "",
        r_2_4_2: "",
        r_2_4_3: "",
        r_2_4_4: "",
        r_2_4_5: "",
        r_2_4_6: "",
        r_2_4_7_1: "",
        r_2_4_7_2: "",
        r_2_4_7_3: "",
        r_2_4_7_4: "",
        r_2_4_7_5: "",
        r_2_5_1: "",
        r_2_5_2: "",
        r_2_5_3: "",
        r_2_5_4: "",
        r_2_5_5: "",
        r_2_5_6: "",
        r_2_6_1: "",
        r_2_6_2: "",
        r_2_6_3: "",
        r_2_6_4: "",
        r_2_6_5: "",
        r_2_6_6_1: "",
        r_2_6_6_2: "",
        r_3_1_1: "",
        r_3_1_2: "",
        r_3_1_3: "",
        r_3_1_4: "",
        r_3_1_5: "",
        r_3_1_6: "",
        r_3_1_7: "",
        r_3_1_8: "",
        r_3_1_9: "",
        r_3_1_10: "",
        r_3_2_1: "",
        r_3_2_2: "",
        r_3_2_3: "",
        r_3_2_4: "",
        r_3_2_5: "",
        r_3_3_1_1: "",
        r_3_3_1_2: "",
        r_3_3_2: "",
        r_3_3_3: "",
        r_3_3_4: "",
        r_3_3_4_1: "",
        r_3_3_4_2: "",
        r_3_3_4_3: "",
        r_3_3_5: "",
        r_3_3_6_1: "",
        r_3_3_6_2: "",
        r_3_3_6_3: "",
        r_3_3_6_4: "",
        r_3_3_6_5: "",
        r_3_3_6_6: "",
        r_3_3_6_7: "",
        r_3_4_1: "",
        r_3_4_2_1: "",
        r_3_4_2_2: "",
        r_3_4_2_3: "",
        r_3_4_2_4: "",
        r_3_4_2_5: "",
        r_3_4_2_6: "",
        r_3_4_3: "",
        r_4_1_1: "",
        r_4_1_2: "",
        r_4_1_3: "",
        r_4_1_4: "",
        r_4_1_5: "",
        r_4_1_6: "",
        r_4_1_7: "",
        r_4_1_8_1: "",
        r_4_1_8_2: "",
        r_4_1_8_3: "",
        r_4_1_8_4: "",
        r_4_1_9_1: "",
        r_4_1_9_2: "",
        r_4_1_9_3: "",
        r_4_1_9_4: "",
        r_4_1_9_5: "",
        r_4_1_10_1: "",
        r_4_1_10_2: "",
        r_4_1_10_3:"",
        r_4_1_11: "",
        
        r_4_2_1: "",
        r_4_2_2: "",
        r_4_2_3: "",
        r_4_3_1: "",

        imgUrl1: "",
        imgUrl2: "",
        imgUrl3: "",
        imgUrl4: "",
        imageName1: "",
        imageName2: "",
        imageName3: "",
        imageName4: "",
      },
      showImage1: "",
      showImage2: "",
      showImage3: "",
      showImage4: "",
      items: [],
      menufrom: false,

      // saleCase: "",
      // possessionSaleCase: "",
      // possessionCase: "",
      // drugCase: "",
    };
  },
  async created() {
    var checklogin = JSON.parse(
      Decode.decode(localStorage.getItem("Summarydata"))
    );
    console.log("checklogin", checklogin);
    this.data.userId = checklogin.userId;
    this.data.station = checklogin.station;
    this.searchDropdown();
    const response = await this.axios.post(
      `${process.env.VUE_APP_API}/summaryForms/findAll?station=` +
        this.data.station,
      {}
    );
    console.log("getstation11111", response.data);
    if (response.data.data.length > 0) {
      const responseUser = await this.axios.get(
        `${process.env.VUE_APP_API}/user/getOne/` + response.data.data[0].userId
      );
      console.log("responseUser", responseUser);
      if (responseUser.data.response_status == "SUCCESS") {
        console.log(responseUser);
        this.data = response.data.data[0];
        this.data.datefrom = this.convertDate(response.data.data[0].updatedAt);
        this.data.staffName = responseUser.data.data.name;
        // this.data.userId = checklogin.id;
        console.log(this.data.staffName);
        this.summaryData = response.data.summaryData[0][0]
        this.summaryDataArray.push({
          number:"1",
          title:"หัวหน้าสถานี(1-7)",
          value:this.summaryData.sum1,
        })
        this.summaryDataArray.push({
          number:"2",
          title:"ขั้นเตรียมการ(2.1-2.6)",
          value:this.summaryData.sum2,
        })
        this.summaryDataArray.push({
          number:"3",
          title:"ขั้นตอนการปฏิบัติ (3.1 - 3.4)",
          value:this.summaryData.sum3,
        })
        this.summaryDataArray.push({
          number:"4",
          title:"ขั้นส่งต่อความยั่งยืน (4.1- 4.3)",
          value:this.summaryData.sum4,
        })
        this.summaryDataArray.push({
          number:"5",
          title:"ค่าคะแนนรวม",
          value:this.summaryData.sum1*1 + this.summaryData.sum2*1 + this.summaryData.sum3*1 + this.summaryData.sum4*1 ,
        })
      
      } else {
        this.data.staffName = "-";
      }
    }
  },
  methods: {
    show1() {
      this.showDiv1 = !this.showDiv1;
    },
    show2() {
      this.showDiv2 = !this.showDiv2;
    },
    show3() {
      this.showDiv3 = !this.showDiv3;
    },
    show4() {
      this.showDiv4 = !this.showDiv4;
    },
    convertDate(date) {
      return moment(String(date)).format("DD/MM/YYYY hh:mm");
    },
    cancel() {
      this.$router.push("/report");
    },
    async searchDropdown() {
      // const response = await this.axios.get(
      //   `https://policesurveyservice.yuzudigital.com/form/getAllStation`
      // );
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);
      this.items.forEach((item) => {
        // item.searchText = item.station + " " + item.village;
        item.searchText = item.station;
      });
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        this.loading = true;
        // console.log("เข้าเฉย");

        console.log("data", this.data);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/summaryForms/create`,
          this.data
          // this.data
        );
        console.log("response", response);
        if (response.data.response_status === "SUCCESS") {
          console.log("response", response);
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "success",
            text: "บันทึกข้อมูลสำเร็จ",
          });
          // this.submit2();
          this.loading = false;
          location.reload();
          // window.close()
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "บันทึกข้อมูลไม่สำเร็จ",
          });
        }
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 3600,
          timerProgressBar: true,
          icon: "error",
          title: "ท่านกรอกข้อมูลไม่ครบ",
          text: "กรุณาตรวจสอบข้อมูลอีกครั้ง",
        });
      }
    },
    async submit2() {
      console.log(this.data);
      if (this.$refs.createForm.validate(true)) {
        this.loading = true;
        // console.log("เข้าเฉย");

        console.log("data", this.data);
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/summaryForms/create`,
          this.data
          // this.data
        );
        console.log("response", response);
        if (response.data.response_status === "SUCCESS") {
          console.log("response", response);
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "success",
            text: "บันทึกข้อมูลสำเร็จ",
          });
          this.loading = false;
          // location.reload();
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            icon: "error",
            text: "บันทึกข้อมูลไม่สำเร็จ",
          });
        }
      }
    },
    async showPicture1(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.data.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.data.imgUrl1 = reader.result;
          this.showImage1 = URL.createObjectURL(element);
          console.log(this.showImage1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    showPicture2(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.data.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.data.imgUrl2 = reader.result;
          this.showImage2 = URL.createObjectURL(element);
          // console.log(this.showImage1);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    showPicture3(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.data.imageName3 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.data.imgUrl3 = reader.result;
          this.showImage3 = URL.createObjectURL(element);
          // console.log(this.showImage1);
        };
      }
    },
    changePic3() {
      document.getElementById("picTure3").click();
    },
    showPicture4(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.data.imageName4 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.data.imgUrl4 = reader.result;
          this.showImage4 = URL.createObjectURL(element);
          // console.log(this.showImage1);
        };
      }
    },
    changePic4() {
      document.getElementById("picTure4").click();
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
